.logo {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 0;

  span {
    color: var(--color-text-light-blue);
    margin-left: 8px;
    font-size: var(--font-size-lg);
    font-weight: 500;
  }

  &.small span {
    font-size: var(--font-size-md);
  }

  &.big span {
    font-size: var(--font-size-xl);
  }

  &.hide-text {
    @media screen and (max-width: 480px) {
      span {
        display: none;
      }
    }
  }
}
