.root {
  background-color: var(--color-modal-overlay);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.backdrop {
  background-color: unset !important;
}
