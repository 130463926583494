.root {
  $padding: 6px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: var(--height-navbar-banner);
  overflow: hidden;
  background: linear-gradient(90deg, #445ae9 0%, #3099d7 100%);
  padding: $padding;

  font-size: var(--font-size-xs);

  @media screen and (max-width: 767px) {
    position: relative;
    cursor: pointer;
    justify-content: flex-start;
  }

  .text {
    display: table;
    white-space: pre;
    position: relative;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      left: 0;
      transform: translateX(0);

      animation: scrollTextPhaseOne 10s linear 3s 1,
        scrollTextPhaseTwo 20s linear 13s infinite;
    }

    @keyframes scrollTextPhaseOne {
      0% {
        left: 0;
        transform: translateX(0);
      }
      100% {
        left: 0;
        transform: translateX(-100%);
      }
    }

    @keyframes scrollTextPhaseTwo {
      0% {
        left: calc(100% + $padding);
        transform: translateX(0);
      }
      50% {
        left: 0;
        transform: translateX(0);
      }
      100% {
        left: 0;
        transform: translateX(-100%);
      }
    }

    > span {
      color: rgba(255, 222, 89, 1);
      margin: 0 4px;
    }
  }
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  border-radius: 5px;

  color: white;
  background-color: var(--color-card);
  padding: 60px;

  outline: none;

  > p {
    margin: 0 0 30px 0;
  }

  > span {
    color: var(--color-primary);
  }
}
