.menuButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
}

.menuButton {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 6px 0;
}

.menuButton::before {
  content: "";
  position: relative;
  top: -6px;

  width: 28px;
  height: 3px;
  background-color: white;
  border-radius: 20px;

  transform: rotate(0);
  transition: top 0.5s, transform 0.5s;
}

.menuButton::after {
  content: "";
  position: relative;
  bottom: -6px;

  width: 28px;
  height: 3px;
  background-color: white;
  border-radius: 20px;

  transform: rotate(0);
  transition: bottom 0.5s, transform 0.5s;
}

.menuButton.crossAnimation::before {
  top: 3px;
  transform: rotate(-45deg);
}

.menuButton.crossAnimation::after {
  bottom: 3px;
  transform: rotate(45deg);
}

.middleBar {
  width: 28px;
  height: 3px;
  background-color: white;
  border-radius: 20px;
  position: relative;
  transition: background-color 0.5s;
}

.menuButton.crossAnimation > .middleBar {
  background-color: transparent;
}
