.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--height-navbar-sm);

  position: relative;
}

.nav-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu {
  position: fixed;
  left: 0;
  width: 100vw;
  z-index: var(--z-index-nav);
  background: var(--color-background);

  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  transition: height 0.25s;
}

.menu > * {
  margin: 20px;
}

.menu.hide {
  height: 0 !important;
}

.menu .divider {
  margin-top: 8px;
  margin-bottom: 8px;
  width: calc(60% - 20px * 2);
  border-bottom: 2px solid var(--color-divider-dark);
}

.link {
  cursor: pointer;
  font-size: var(--font-size-xl);
  font-weight: 500;
  transition: color 200ms;
}
.link:hover {
  color: var(--color-primary);
}
