.popperWrapper {
  // https://github.com/mui/material-ui/issues/27301

  &[data-popper-placement*="bottom"] .arrow {
    top: 0;
    left: 0;
    margin-top: -0.9em;
    width: 3em;
    height: 1em;

    &::before {
      border-width: 0 1em 1em 1em;
      border-color: transparent transparent var(--color-background-light)
        transparent;
    }
  }

  &[data-popper-placement*="top"] .arrow {
    bottom: 0;
    left: 0;
    margin-bottom: -0.9em;
    width: 3em;
    height: 1em;

    &::before {
      border-width: 1em 1em 0 1em;
      border-color: var(--color-background-light) transparent transparent
        transparent;
    }
  }

  &[data-popper-placement*="right"] .arrow {
    left: 0;
    margin-left: -0.9em;
    height: 3em;
    width: 1em;

    &::before {
      border-width: 1em 1em 1em 0;
      border-color: transparent var(--color-background-light) transparent
        transparent;
    }
  }

  &[data-popper-placement*="left"] .arrow {
    right: 0;
    margin-right: -0.9em;
    height: 3em;
    width: 1em;

    &::before {
      border-width: 1em 0 1em 1em;
      border-color: transparent transparent transparent
        var(--color-background-light);
    }
  }
}

.popper {
  padding: 16px;
  gap: 8px;
  border-radius: 5px;

  display: flex;
  flex-direction: column;

  background-color: var(--color-background-light);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 7px 29px 0px;
}

.popper-item {
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 0;

  color: white;

  > svg {
    fill: white;
    height: 20px;
    width: 20px;
  }

  &:hover {
    color: var(--color-primary);

    > svg {
      fill: var(--color-primary);
    }
  }
}

.arrow {
  font-size: 7px;
  width: 3em;
  height: 3em;

  &::before {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
}
