.try-for-free {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 36px;
  padding: 8px 12px;
  border-radius: 5px;

  background-color: var(--color-yellow);
  font-weight: 500;
  color: black;
  cursor: pointer;

  margin: 12px 20px 12px 12px;
}

.try-for-free-bg {
  font-size: var(--font-size-bg);
  padding: 28px 20px;
  border-radius: 8px;
}

.try-for-free-sm {
  font-size: var(--font-size-sm);
}
