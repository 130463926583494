.nav-wrapper {
  position: sticky;
  left: 0;
  top: 0;
  z-index: var(--z-index-nav);
  background: var(--color-background);

  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  transition: background 300ms ease 0s, opacity 0.25s, visibility 0.25s;
}

.nav-wrapper.float {
  position: fixed;
}

.nav-wrapper.blend {
  position: static;
  background: transparent;
}

.nav-wrapper.hidden {
  opacity: 0;
  visibility: hidden;
}

.nav {
  width: 100%;
  max-width: var(--max-width-app);
  height: var(--height-navbar);
  padding: 0 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav .nav-left {
  display: flex;
  flex-direction: row;
}

.nav .nav-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav .links {
  display: flex;
  align-items: center;
}

.nav .link {
  padding: 8px;
  margin: 10px;
  cursor: pointer;
  font-size: var(--font-size-sm);
  transition: color 200ms;
}
.nav .link:hover {
  color: var(--color-primary);
}

.popper-wrapper {
  z-index: var(--z-index-nav);
  max-height: min(200px, calc(var(--height-app) - var(--height-navbar) - 8px));
}

.popper {
  gap: 8px !important;
  width: 280px;
  max-height: calc(var(--height-app) - 20px * 2);
  overflow: auto;
  padding: 16px 8px !important;
}

.popper-item-extend {
  font-size: var(--font-size-md);
  color: white;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  width: 100%;
  padding: 8px 10px !important;
  border-radius: 8px;
  transition: background-color 200ms;

  &:hover {
    background-color: var(--color-card-light);
    color: white !important;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 8px;

    > svg {
      width: 26px;
      height: 26px;
    }
  }
}

.divider {
  width: calc(100% - 10px * 2);
  align-self: center;
  border-bottom: 1px solid var(--color-divider-light);
  margin: 4px 0;
}

.progressContainer {
  position: relative;
  height: fit-content;
  display: flex;
}

.progress {
  width: 36px;
  height: 36px;
  border-radius: 50%;

  background-color: var(--color-background);
  color: var(--color-secondary-light) !important;
}

$progress-thickness: 14px;

.progressCenter {
  position: absolute;
  top: calc(40px / 2 - (40px - $progress-thickness) / 2);
  left: calc(40px / 2 - (40px - $progress-thickness) / 2);
  height: calc(40px - $progress-thickness);
  width: calc(40px - $progress-thickness);
  border-radius: 50%;
  background-color: var(--color-background-light);

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-sm);
}

.earlyAccessPopperWrapper {
  z-index: var(--z-index-nav);
}

.earlyAccessPopperContent {
  display: flex;
  flex-direction: column;

  max-width: calc(360px - 16px * 2); // 16px is padding of custom popper
  max-height: calc(var(--height-app) - 16px * 2 - var(--height-navbar));
  overflow: auto;
  color: white;
  gap: 16px;

  > p {
    margin: 0;
    font-size: var(--font-size-sm);
    font-weight: 300;
    line-height: 22px;
  }

  > button {
    position: relative;
    cursor: pointer;

    outline: unset;
    border: unset;

    font-size: var(--font-size-sm);
    color: black;
    background-color: var(--color-yellow);

    padding: 8px 16px;
    border-radius: 20px;
    line-height: 20px;
    font-weight: 500;
    font-family: inherit;

    $button-height: calc(8px * 2 + 20px);

    > svg {
      position: absolute;
      top: calc($button-height / 2 - 30px / 2);
      right: 5px;
      width: 30px;
      height: 30px;
    }

    &.purpleButton {
      background-color: var(--color-secondary);
      color: white;

      > svg {
        fill: white;
      }
    }
  }
}
