.root {
  display: flex;
  flex-direction: column;

  outline: unset;

  width: 520px;
  height: fit-content;

  max-width: 100vw;
  max-height: var(--height-app);

  position: relative;
  padding: 20px 28px;

  @media screen and (max-width: 767px) {
    padding: 20px;
  }
}

.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 12px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > p {
    margin: 0;
    color: var(--color-text-highlight);

    > span {
      margin-right: 8px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      text-align: center;
    }
  }

  > .iconContainer {
    > svg {
      height: 16px;
      width: 16px;

      fill: white;
      cursor: pointer;
      transition: fill 200ms;

      &:hover {
        fill: var(--color-secondary);
      }
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  @media screen and (max-width: 767px) {
    gap: 16px;
    flex-direction: column-reverse;
  }
}

.images {
  margin-top: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 28px;

  width: 100%;

  > svg {
    $ratio: calc(88px / 50px); // width / height
    $width: 80px;

    width: $width;
    height: calc($width / $ratio);
  }
}

.text {
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  color: white;
  gap: 16px;

  > p {
    margin: 0;
    font-weight: 300;
    line-height: 22px;
    font-size: var(--font-size-sm);
  }

  > .bold {
    font-weight: 600;
  }
}

.action {
  margin: 24px 0 12px 0;

  display: flex;
  justify-content: center;

  > button {
    outline: unset;
    border: unset;
    border-radius: 20px;
    background-color: var(--color-yellow);

    user-select: none;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 26px;

    font-size: var(--font-size-sm);
    font-weight: 500;
    font-family: inherit;

    cursor: pointer;
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  border-radius: 5px;

  color: white;
  background-color: var(--color-card);
  padding: 60px;

  outline: none;

  > p {
    margin: 0 0 30px 0;
  }

  > span {
    color: var(--color-primary);
  }
}
