.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.tooltip {
  margin-top: 10px !important;
  margin-right: -2px !important;
  background-color: rgb(37, 37, 37) !important;
  border: 1px solid #848484;
  color: rgba(255, 255, 255, 0.8) !important;
  padding: 5px 10px !important;
}

.progressContainer {
  cursor: pointer;

  position: relative;
  left: 20px;
  height: fit-content;
  display: flex;
}

.progress {
  width: 36px;
  height: 36px;
  border-radius: 50%;

  background-color: var(--color-background-light);
  color: var(--color-secondary-light) !important;
}

$progress-thickness: 14px;

.progressCenter {
  position: absolute;
  top: calc(40px / 2 - (40px - $progress-thickness) / 2);
  left: calc(40px / 2 - (40px - $progress-thickness) / 2);
  height: calc(40px - $progress-thickness);
  width: calc(40px - $progress-thickness);
  border-radius: 50%;
  background-color: black;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-sm);
}

.chip {
  cursor: pointer;

  display: flex;
  height: fit-content;
  font-size: var(--font-size-sm);
  background-color: var(--color-background-light);
  padding: 4px 12px 4px 26px;
  border-radius: 20px;
}

.popperWrapper {
  z-index: var(--z-index-nav);
}

.popperContent {
  display: flex;
  flex-direction: column;

  max-width: calc(360px - 16px * 2); // 16px is padding of custom popper
  max-height: calc(var(--height-app) - 16px * 2 - var(--height-navbar));
  overflow: auto;
  color: white;
  gap: 16px;

  > p {
    margin: 0;
    font-size: var(--font-size-sm);
    font-weight: 300;
    line-height: 22px;
  }

  > button {
    position: relative;
    cursor: pointer;

    font-size: var(--font-size-sm);
    color: black;
    background-color: var(--color-yellow);

    outline: unset;
    border: unset;
    background-color: var(--color-yellow);
    padding: 8px 16px;
    border-radius: 20px;
    line-height: 20px;
    font-weight: 500;
    font-family: inherit;

    $button-height: calc(8px * 2 + 20px);

    > svg {
      position: absolute;
      top: calc($button-height / 2 - 30px / 2);
      right: 5px;
      width: 30px;
      height: 30px;
    }

    &.purpleButton {
      background-color: var(--color-secondary);
      color: white;

      > svg {
        fill: white;
      }
    }
  }
}
